<template>
<div id="infoItem">
  <div class="hidden-md-and-up">
    <m-top/>

    <div>
      <div class="m-title">{{item.title}}</div>
      <div class="m-time">{{item.createTime}}</div>
      <div class="m-content">{{item.content}}</div>
    </div>

    <m-access/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import MTop from "../components/m/MTop";
import MAccess from "../components/m/MAccess";
import MFooter from "../components/m/MFooter";
import moment from "moment";
export default {
  name: "InfoItem",
  data() {
    return {
      item: []
    }
  },
  components: {MFooter, MAccess, MTop},
  created() {
    let id = this.$route.query.id
    axios.get(`${this.GLOBAL.host}/t/info/get/${id}`).then(resp => {
      resp.data.createTime = moment(resp.data.createTime).format('YYYY.MM.DD')
      this.item = resp.data
    })
  }
}
</script>

<style scoped>
#infoItem {
  border-top: 10px solid #1796c4;
}

.m-title {
  margin: 2rem 2rem 0.5rem 2rem;
  padding-bottom: 1rem;
  color: #62b6e2;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  border-bottom: 2px dotted #62b6e2;
}
.m-time {
  text-align: center;
  color: #333;
  font-size: 12px;
}
.m-content {
  white-space: pre-line;
  padding: 1rem;
  line-height: 24px;
  background-color: #FFFFFF;
  margin-top: 1rem;
  color: #333;
}
</style>
